<!-- <div class="full-screen-ad">
  <div class="full-screen-ad">
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/Z-gqzN65iHg"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <button *ngIf="showCloseButton" (click)="closeAd()" class="close-button">
    {{ 'General.Close' | translate }}
  </button>
</div> -->
<!-- <div *ngIf="showFullScreenAd$ | async"></div> -->
<!-- full-screen-ad.component.html -->
<!-- showFullScreenAd: {{ showFullScreenAd$ | async }} -->

<div class="full-screen-ad" *ngIf="showFullScreenAd$ | async">
  <div id="player"></div>
</div>
