import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomSpinnerComponent } from '../custom-spinner/custom-spinner.component';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  standalone: false
  // imports: [MatProgressSpinnerModule, MatDialogModule, CustomSpinnerComponent]
})
export class AppProgressSpinner {}
