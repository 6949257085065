import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  Subject,
  tap
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Subscription, SubscriptionStore } from '../models/subscription.model';
import { FeedbackService } from './feedback.service';
import { ProgressSpinnerService } from './progress-spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackDialog } from '../models/feedback-dialog.model';
import { Router } from '@angular/router';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private readonly _subscriptionStore = new BehaviorSubject<SubscriptionStore>({
    content: []
  } as SubscriptionStore);

  readonly subscriptionStore$ = this._subscriptionStore.asObservable();

  constructor(
    private http: HttpClient,
    private feedbackService: FeedbackService,
    private progressSpinnerService: ProgressSpinnerService,
    private translate: TranslateService,
    private router: Router,
    private localService: LocalService
  ) {}

  //
  // Setter treatment collection
  //
  private _setSubscriptionStore(subscriptionStore: SubscriptionStore): void {
    this._subscriptionStore.next(subscriptionStore);
  }

  //
  // Exposed all treatment collection as UNObservable
  //
  getSubscriptionStore(): SubscriptionStore {
    return this._subscriptionStore.getValue();
  }

  //
  // Safe update of treatment collection
  //
  updateSubscriptionStore(subscriptionStore: SubscriptionStore): void {
    this._setSubscriptionStore({
      ...this.getSubscriptionStore(),
      ...subscriptionStore
    });
  }

  //
  // Clean project's subscription
  //
  cleanSubscriptionStore(): void {
    // #sr console.log(
    //   '############################# Clear Subscription Store ############################'
    // );
    this._setSubscriptionStore({
      content: []
    } as SubscriptionStore);
  }

  //
  // Http request for subscriptions with filter set on Backend (new Date)
  //

  getAllActiveSubscriptionsHttp(): Observable<void> {
    const sanitizedUsername = this.localService.getSanitizedUsername();
    if (!sanitizedUsername) {
      return of(void 0);
    }
    const uri = `/subscription/active?id=${sanitizedUsername}`;
    this.cleanSubscriptionStore();

    return this.http.get<Subscription[]>(uri).pipe(
      tap(response => {
        const allSubscriptions = response as Subscription[];
        this.updateSubscriptionStore({ content: allSubscriptions });
      }),
      map(() => void 0), // Map the response to void
      catchError(error => {
        console.error('Error fetching subscriptions', error);

        const feedbackDialog = {
          id: 'E405',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant(
            'Dialog.AllSubscribesNotFetched'
          )}`,
          style: 'warning'
        } as FeedbackDialog;

        const subject = new Subject<void>();

        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          subject.next(); // Signal completion
          subject.complete();
        });

        return subject.pipe(map(() => void 0));
      }),
      tap(() => {})
    );
  }

  isValidSubscription(): boolean {
    const subscriptions = this.getSubscriptionStore().content;
    const today = new Date();

    return subscriptions.some(subscription => {
      const validToDate = new Date(subscription.validTo);
      validToDate.setDate(validToDate.getDate() + 1);
      return today < validToDate && !subscription.sms;
    });
  }
}
