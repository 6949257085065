import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  map,
  of,
  tap
} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Treatment, TreatmentStore } from '../models/treatment.model';

import { FeedbackService } from './feedback.service';
import { ProgressSpinnerService } from './progress-spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackDialog } from '../models/feedback-dialog.model';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalService } from './local.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TreatmentsService {
  constructor(
    private http: HttpClient,
    private feedbackService: FeedbackService,
    private progressSpinnerService: ProgressSpinnerService,
    private translate: TranslateService,
    private router: Router,
    private localService: LocalService
  ) {}

  //
  // Store of treatment id
  //
  private _treatmentIdStore = new BehaviorSubject<number>(0);
  public readonly treatmentId$ = this._treatmentIdStore.asObservable(); // it is read only

  setTreatmentIdStore(id: number): void {
    this._treatmentIdStore.next(id);
  }

  //
  // Clean treatment Id Store
  //
  cleanTreatmentIdStore(): void {
    this._treatmentIdStore.next(0);
  }

  //
  // Expose Treatment's Id as UNObservable, as number
  //
  getTreatmentIdStore(): number {
    return this._treatmentIdStore.getValue();
  }

  //
  // Store of treatment
  //
  private readonly _treatmentStore = new BehaviorSubject<TreatmentStore>({
    content: []
  } as TreatmentStore);

  //
  // Setter treatment collection
  //
  private _setTreatmentStore(treatmentStore: TreatmentStore): void {
    this._treatmentStore.next(treatmentStore);
  }

  //
  // Exposed all treatment collection as Observable
  //
  readonly treatmentStore$ = this._treatmentStore.asObservable();

  //
  // Exposed all treatment collection as UNObservable
  //
  getTreatmentStore(): TreatmentStore {
    return this._treatmentStore.getValue();
  }

  //
  // Safe update of treatment collection
  //
  updateTreatmentStore(treatmentStore: TreatmentStore): void {
    this._setTreatmentStore({
      ...this.getTreatmentStore(),
      ...treatmentStore
    });
  }

  //
  // Clean project's treatment
  //
  cleanTreatmentStore(): void {
    this._setTreatmentStore({
      content: []
    } as TreatmentStore);
  }

  //
  // Extract all Treatment Groups
  //
  extractTreatmentGroups(treatmentStore: TreatmentStore): string[] {
    return treatmentStore.content.map(treatment => treatment.treatmentGroup);
  }

  //
  // Extract all unique Treatment Groups
  //
  extractUniqueTreatmentGroups(treatmentStore: TreatmentStore): string[] {
    const uniqueTreatmentGroups: string[] = [];

    treatmentStore.content.forEach(treatment => {
      if (!uniqueTreatmentGroups.includes(treatment.treatmentGroup)) {
        uniqueTreatmentGroups.push(treatment.treatmentGroup);
      }
    });
    uniqueTreatmentGroups.sort();
    return uniqueTreatmentGroups;
  }

  //
  // Extract all unique Treatment Names
  //
  extractUniqueTreatmentNames(treatmentStore: TreatmentStore): string[] {
    const uniqueTreatmentNames: string[] = [];

    treatmentStore.content.forEach(treatment => {
      if (!uniqueTreatmentNames.includes(treatment.name)) {
        uniqueTreatmentNames.push(treatment.name);
      }
    });
    uniqueTreatmentNames.sort();
    return uniqueTreatmentNames;
  }

  //
  // Http create treatment and its address
  //

  createTreatmentHttp(treatment: Treatment): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    this.progressSpinnerService.show();

    const uriTreatment = `/treatments/create?id=${sanitizedUsername}`;

    type ResponseNewTreatmentType = {
      treatment?: Treatment;
      message: string;
    };
    this.http
      .post<ResponseNewTreatmentType>(uriTreatment, treatment)
      .subscribe({
        next: response => {
          const message = response.message;
          const createdTreatment = response.treatment as Treatment;

          const allTreatments = this.getTreatmentStore().content as Treatment[];
          allTreatments.push(createdTreatment);
          this.updateTreatmentStore({ content: allTreatments });
          const feedbackDialog = {
            title: this.translate.instant('Dialog.Info'),
            message: `${this.translate.instant('Dialog.TreatmentCreated')}: <br>
            <b> ${this.translate.instant('Dialog.GroupName')}: </b>${
              treatment.treatmentGroup
            } <b> ${this.translate.instant('General.ProductName')}: </b> ${
              treatment.name
            } <br>`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
        },
        error: error => {
          const feedbackDialog = {
            id: 'E200',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant(
              'Dialog.TreatmentNotCreated'
            )}: <br>
            <b> ${this.translate.instant('Dialog.GroupName')}: </b>${
              treatment.name
            } <b> ${this.translate.instant('General.ProductName')}: </b> ${
              treatment.name
            } <br>`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
        },
        complete: () => {
          this.progressSpinnerService.hide();
          this.router.navigate(['/apps/treatment']);
        }
      });
  }

  // PUT Modify

  //
  // Http update treatment
  //

  updateTreatmentHttp(treatmentUpdate: Treatment): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      // #sr console.log('### Http Error no user, no email');
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    this.progressSpinnerService.show();
    const uriTreatment = `/treatments/update?id=${sanitizedUsername}`;

    type ResponseNewTreatmentType = {
      treatment?: Treatment;
      message: string;
    };

    // #sr console.log('### We start to make HTTP request to Backend with DB..');
    this.http
      .put<ResponseNewTreatmentType>(uriTreatment, treatmentUpdate)
      .subscribe({
        next: response => {
          const message = response.message;
          const allTreatments = this.getTreatmentStore().content as Treatment[];

          const updatedTreatments = allTreatments.map((treatment: Treatment) =>
            treatment.id === treatmentUpdate.id
              ? {
                  ...treatment,
                  treatmentGroup: treatmentUpdate.treatmentGroup,
                  name: treatmentUpdate.name,
                  description: treatmentUpdate.description,
                  contraindications: treatmentUpdate.contraindications,
                  duration: treatmentUpdate.duration,
                  cost: treatmentUpdate.cost,
                  discount: treatmentUpdate.discount,
                  createdDate: treatmentUpdate.createdDate,
                  active: treatmentUpdate.active
                }
              : treatment
          );
          this.updateTreatmentStore({ content: updatedTreatments });
          const feedbackDialog = {
            title: this.translate.instant('Dialog.Info'),
            message: `${this.translate.instant('Dialog.TreatmentUpdated')}: <br>
            <b> ${this.translate.instant('Dialog.GroupName')}: </b>${
              treatmentUpdate.treatmentGroup
            } <b> ${this.translate.instant('General.ProductName')}: </b> ${
              treatmentUpdate.name
            } <br>`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
        },
        error: error => {
          const feedbackDialog = {
            id: 'E202',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant(
              'Dialog.TreatmentNotUpdated'
            )}: <br>
            <b> ${this.translate.instant('Dialog.GroupName')}: </b>${
              treatmentUpdate.treatmentGroup
            } <b> ${this.translate.instant('General.ProductName')}: </b> ${
              treatmentUpdate.name
            } <br>`,
            style: 'warning'
          } as FeedbackDialog;

          this.feedbackService.setFeedbackStore(feedbackDialog, () => {
            this.router.navigate(['/apps/treatment']);
          });
        },
        complete: () => {
          this.progressSpinnerService.hide();
          this.router.navigate(['/apps/treatment']);
        }
      });
  }

  getTreatment$(id: number): Observable<Treatment> {
    return this.treatmentStore$.pipe(
      map(treatmentStore => treatmentStore.content),
      map(treatments => treatments.filter(treatment => treatment.id === id)[0])
    );
  }

  getTreatmentBasedOnTreatmentId(): Treatment {
    // #sr console.log(
    //   'getTreatmentBasedOnTreatmentId() id=',
    //   this.getTreatmentIdStore()
    // );
    return (
      this.getTreatmentStore().content.filter(
        treatment => treatment.id === this.getTreatmentIdStore()
      )[0] ?? null
    );
  }

  getTreatmentBasedOnId(id: number): Treatment {
    return (
      this.getTreatmentStore().content.filter(
        treatment => treatment.id === id
      )[0] ?? null
    );
  }

  //
  // Http request for treatments
  //

  getAllTreatmentsHttp(): Observable<void> {
    const sanitizedUsername = this.localService.getSanitizedUsername();
    // if (!sanitizedUsername) {
    //   return of(void 0);
    // }
    const uri = `/treatments/all?id=${sanitizedUsername}`;
    this.cleanTreatmentStore();

    return this.http.get<Treatment[]>(uri).pipe(
      tap(response => {
        const allTreatments = response as Treatment[];
        this.updateTreatmentStore({ content: allTreatments });
      }),
      map(() => void 0), // Map the response to void
      catchError(error => {
        console.error('Error fetching treatments', error);

        const feedbackDialog = {
          id: 'E205',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant('Dialog.AllTreatmentCreated')}`,
          style: 'warning'
        } as FeedbackDialog;

        const subject = new Subject<void>();

        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          subject.next(); // Signal completion
          subject.complete();
        });

        return subject.pipe(map(() => void 0));

        // this.feedbackService.setFeedbackStore(feedbackDialog);
        // return of(void 0);
      }),
      tap(() => {})
    );
  }
  //
  // Http delete treatment
  //

  deleteTreatmentHttp(treatmentId: number): void {
    if (!this.localService.getSanitizedUsername()) {
      const isDemo = environment.demo;
      if (isDemo) {
        this.router.navigate(['/apps/blocked']);
        return;
      }
      // #sr console.log('### Http Error no user, no email');
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    this.progressSpinnerService.show();

    const baseUri = `/treatments/delete?id=${sanitizedUsername}`;
    const options = {
      body: { id: treatmentId } // Include treatmentId in the request body
    };

    this.http
      .delete(`${baseUri}`, options)
      .pipe()
      .subscribe({
        next: response => {
          const updatedTreatments = this.getTreatmentStore().content.filter(
            treatment => treatment.id !== treatmentId
          );
          const deletedTreatment = this.getTreatmentStore().content.filter(
            treatment => treatment.id == treatmentId
          )[0];
          const feedbackDialog = {
            title: this.translate.instant('Dialog.Info'),
            message: `${this.translate.instant('Dialog.TreatmentDeleted')}: <br>
            <b> ${this.translate.instant('Dialog.GroupName')}: </b>${
              deletedTreatment.treatmentGroup
            } <b> ${this.translate.instant('General.ProductName')}: </b> ${
              deletedTreatment.name
            } <br>`,

            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
          this.updateTreatmentStore({ content: updatedTreatments });
        },
        error: error => {
          const feedbackDialog = {
            id: 'E206',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant('Dialog.TreatmentNotDeleted')}`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
        },
        complete: () => {
          this.progressSpinnerService.hide();
        }
      });
  }
}
