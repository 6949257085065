import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LocalService {
  constructor() {}

  public saveData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public getData(key: string) {
    let data = localStorage.getItem(key) || '';
    return data;
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  getSanitizedUsername(): string | null {
    const email = localStorage.getItem('email'); // Assuming the email is stored with the key 'email'
    if (!email) {
      // #sr console.log('No email found in LocalStorage');
      return null;
    }
    const username = email.split('@')[0];
    const sanitizedUsername = username.replace(/\./g, '');
    return sanitizedUsername;
  }
}
