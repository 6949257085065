import { CommonModule, NgForOf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { Component } from '@angular/core';

import { navItems } from '../../menu-data';
import { NavItem } from '../sidebar/nav-item/nav-item';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [
    RouterModule,
    MaterialModule,
    TablerIconsModule,
    FormsModule,
    NgForOf,
    CommonModule,
    TranslateModule
  ],
  templateUrl: 'search-dialog.component.html',
  styleUrls: ['../../../../../styles.scss', './search-dialog.component.scss']
})
export class AppSearchDialogComponent {
  searchText: string = '';
  navItems = quickSearchItems;
  navItemsData = navItems.filter(navitem => navitem.displayName);
  constructor(private router: Router, private translate: TranslateService) {}
  get filteredNavItems() {
    if (!this.searchText.trim()) {
      return this.navItems;
    }

    const searchTextLower = this.searchText.toLowerCase();

    return this.navItems.filter(item => {
      const translatedDisplayName = this.translate.instant(
        'General.' + item.displayName
      );
      return translatedDisplayName.toLowerCase().includes(searchTextLower);
    });
  }

  goTo() {
    this.router.navigate(['/apps/pricing']);
  }
}

export const quickSearchItems: NavItem[] = [
  {
    displayName: 'Home',
    iconName: 'home',
    route: ''
  },
  {
    displayName: 'Calendar',
    iconName: 'calendar-event',
    route: 'apps/calendar'
  },
  {
    displayName: 'Visits',
    iconName: 'list',
    route: 'apps/visit'
  },
  {
    displayName: 'AddVisit',
    iconName: 'circle-plus',
    route: 'apps/addVisit'
  },
  {
    displayName: 'Customers',
    iconName: 'man',
    route: 'apps/customer'
  },
  {
    displayName: 'AddCustomer',
    iconName: 'circle-plus',
    route: 'apps/addCustomer'
  },
  {
    displayName: 'Treatments',
    iconName: 'massage',
    route: 'apps/treatment'
  },
  {
    displayName: 'AddTreatment',
    iconName: 'circle-plus',
    route: 'apps/addTreatment'
  },
  {
    displayName: 'ToDo',
    iconName: 'edit',
    route: 'apps/taskboard'
  },
  {
    displayName: 'Analytical',
    iconName: 'chart-dots',
    route: '/charts/column'
  },
  {
    displayName: 'Pricing',
    iconName: 'currency-dollar',
    route: 'apps/pricing'
  },
  {
    displayName: 'About',
    iconName: 'help',
    route: 'apps/about'
  },
  {
    displayName: 'FAQ',
    iconName: 'checklist',
    route: 'apps/faq'
  },
  {
    displayName: 'Terms and conditions',
    iconName: 'report',
    route: 'apps/terms-and-conditions'
  },
  {
    displayName: 'Contact',
    iconName: 'mail',
    route: 'apps/contact'
  },
  {
    displayName: 'Login',
    iconName: 'login',
    route: 'apps/login'
  },
  {
    displayName: 'Register',
    iconName: 'user-plus',
    route: 'apps/register'
  },
  {
    displayName: 'Logout',
    iconName: 'logout',
    route: 'apps/logout'
  }
];
