import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../../menu-data';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, NgForOf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { shortCutNavItems } from '../../menu-data';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { AppSearchDialogComponent } from './search-dialog.component';
import { UserSettingsService } from 'src/app/services/user-settings.service';

interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    NgScrollbarModule,
    TablerIconsModule,
    MaterialModule,
    TranslateModule
    // BrowserModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['../../../../../styles.scss', './header.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<boolean>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();
  shortCutNavItems = shortCutNavItems!;

  showFiller = false;
  isNavOpen = false;

  toggleNav() {
    this.isNavOpen = !this.isNavOpen;
    this.toggleMobileNav.emit(this.isNavOpen);
  }
  public selectedLanguage: any = {
    language: 'Polski',
    code: 'pl',
    type: 'PL',
    icon: '/assets/images/flag/icon-flag-pl.png'

    // language: 'English',
    // code: 'en',
    // type: 'US',
    // icon: '/assets/images/flag/icon-flag-en.svg',
  };

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg'
    },
    {
      language: 'Polski',
      code: 'pl',
      type: 'PL',
      icon: '/assets/images/flag/icon-flag-pl.png'
    }
    // {
    //   language: 'Español',
    //   code: 'es',
    //   icon: '/assets/images/flag/icon-flag-es.svg',
    // },
    // {
    //   language: 'Français',
    //   code: 'fr',
    //   icon: '/assets/images/flag/icon-flag-fr.svg',
    // },
    // {
    //   language: 'German',
    //   code: 'de',
    //   icon: '/assets/images/flag/icon-flag-de.svg',
    // },
  ];

  constructor(
    private vsidenav: CoreService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private authService: AuthService,
    public userSettingsService: UserSettingsService
  ) {
    translate.setDefaultLang('pl');
  }
  user$ = this.authService.user$;

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      // #sr console.log(`Dialog result: ${result}`);
    });
  }

  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  logout() {
    this.authService.logout();
  }
  login() {
    this.authService.login();
  }

  notifications: notifications[] = [
    {
      id: 1,
      img: '/assets/images/profile/user-9.jpg',
      title: 'Dziś cholera upgrade',
      subtitle: 'Od 11:30 do 13:30'
    },
    {
      id: 2,
      img: '/assets/images/profile/user-2.jpg',
      title: 'Miła wiadomość',
      subtitle: 'W Walentynki 30% rabat'
    }
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'Mój Pulpit',
      subtitle: 'Strona powitalna',
      link: '/'
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-inbox.svg',
      title: 'Moje ustawienia',
      subtitle: 'Zmień swoje ustawienia',
      // TODO uncomment below
      // link: '/apps/user-setting',
      link: '/apps/user-setting'
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-tasks.svg',
      title: 'Tablica To-do',
      subtitle: 'czyli co do zrobienia',
      link: '/apps/taskboard'
    }
  ];

  quicklinks: quicklinks[] = [
    {
      id: 1,
      title: 'Pricing2 Page',
      link: '/theme-pages/pricing'
    },
    {
      id: 2,
      title: 'Authentication Design',
      link: '/authentication/side-login'
    },
    {
      id: 3,
      title: 'Register Now',
      link: '/authentication/side-register'
    },
    {
      id: 4,
      title: '404 Error Page',
      link: '/authentication/error'
    },
    {
      id: 5,
      title: 'Notes App',
      link: '/apps/notes'
    },
    {
      id: 6,
      title: 'Employee App',
      link: '/apps/employee'
    },
    {
      id: 7,
      title: 'Todo Application',
      link: '/apps/todo'
    },
    {
      id: 8,
      title: 'Treeview',
      link: '/theme-pages/treeview'
    }
  ];
}
