import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { CustomersService } from './services/customers.service';
import { TreatmentsService } from './services/treatments.service';
import { VisitsService } from './services/visits.service';
import { ProgressSpinnerService } from './services/progress-spinner.service';
import { TasksService } from './services/tasks.service';
import { Router } from '@angular/router';
import {
  catchError,
  distinct,
  forkJoin,
  Observable,
  of,
  Subscription,
  switchMap,
  tap
} from 'rxjs';
import { AdService } from './services/ad.service';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackDialog } from './models/feedback-dialog.model';
import { FeedbackService } from './services/feedback.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppFeedbackDialog } from './pages/apps/feedback-dialog/feedback-dialog.component';
import { AddressService } from './services/address.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private addressService: AddressService,
    private visitsService: VisitsService,
    private treatmentsService: TreatmentsService,
    private tasksService: TasksService,
    private customersService: CustomersService, // private router: Router
    private progressSpinnerService: ProgressSpinnerService,
    private router: Router,
    public adService: AdService,
    private translate: TranslateService,
    private feedbackService: FeedbackService,
    public dialog: MatDialog
  ) {}

  showFullScreenAd$: Observable<boolean>;
  closeFullScreenAd$: Observable<boolean>;
  newFeedbackDialogSubscription: Subscription;
  newFeedbackDialog$: Observable<FeedbackDialog | null> =
    this.feedbackService.feedbackStore$;
  public dialogRef: MatDialogRef<AppFeedbackDialog>; // Inject MatDialogRef

  ngOnInit(): void {
    this.showFullScreenAd$ = this.adService.fullScreenAd$;
    this.getAllStores();
  }

  title = 'Salon-Care';

  logout() {
    this.authService.logout();
  }

  getAllStores() {
    this.progressSpinnerService.show();
    forkJoin([
      this.treatmentsService.getAllTreatmentsHttp(),
      this.tasksService.getAllTasksHttp(),
      this.customersService.getAllCustomersHttp(),
      //  this.addressService.getAddressHttp(),
      this.visitsService.getAllVisitsHttp()
    ])
      .pipe(
        tap(results => {
          this.visitsService.getCalendarEventsFromStore();
          // Handle results if necessary
        }),
        catchError(error => {
          console.error('Error fetching all stores', error);
          return forkJoin({
            title: this.translate.get('Dialog.Error'),
            code: this.translate.get('Dialog.Code'),
            description: this.translate.get('General.Description'),
            taskNotCreated: this.translate.get('Dialog.TaskNotCreated')
          }).pipe(
            tap(translations => {
              console.log('AppComponent getAllStores()');

              const feedbackDialog = {
                id: 'E300',
                title: translations.title,
                message: `<b>${translations.code}:</b> E300 <br> <b>${translations.description}:</b> ${translations.taskNotCreated}`,
                style: 'warning'
              } as FeedbackDialog;

              this.feedbackService.setFeedbackStore(feedbackDialog, () => {
                this.router.navigate(['/dashboards/dashboard1']);
              });
            }),
            switchMap(() => {
              return of(null); // Returning a non-error observable
            })
          );
        }),
        tap({
          complete: () => {
            // console.log('Complete...');
            this.progressSpinnerService.hide();
          }
        })
      )
      .subscribe();
  }
  loadData() {
    this.progressSpinnerService.show();

    // Simulate an HTTP request
    setTimeout(() => {
      this.progressSpinnerService.hide();
    }, 9000);
  }
}
