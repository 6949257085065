import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  map,
  of,
  tap
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResponseNewTaskType, Task, TaskStore } from '../models/task.model';
import { FeedbackService } from './feedback.service';
import { ProgressSpinnerService } from './progress-spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackDialog } from '../models/feedback-dialog.model';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalService } from './local.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  constructor(
    private http: HttpClient,
    private feedbackService: FeedbackService,
    private progressSpinnerService: ProgressSpinnerService,
    private translate: TranslateService,
    private router: Router,
    private localService: LocalService
  ) {}

  //
  // Store of task id
  //
  private _taskIdStore = new BehaviorSubject<number>(0);
  public readonly taskId$ = this._taskIdStore.asObservable(); // it is read only

  setTaskIdStore(id: number): void {
    this._taskIdStore.next(id);
  }

  //
  // Clean task Id Store
  //
  cleanTaskIdStore(): void {
    this._taskIdStore.next(0);
  }

  //
  // Expose Task's Id as UNObservable, as number
  //
  getTaskIdStore(): number {
    return this._taskIdStore.getValue();
  }

  //
  // Store of task
  //
  private readonly _taskStore = new BehaviorSubject<TaskStore>({
    content: []
  } as TaskStore);

  //
  // Setter task collection
  //
  private _setTaskStore(taskStore: TaskStore): void {
    this._taskStore.next(taskStore);
  }

  //
  // Exposed all task collection as Observable
  //
  readonly taskStore$ = this._taskStore.asObservable();

  //
  // Exposed all task collection as UNObservable
  //
  getTaskStore(): TaskStore {
    return this._taskStore.getValue();
  }

  //
  // Safe update of task collection
  //
  updateTaskStore(taskStore: TaskStore): void {
    this._setTaskStore({
      ...this.getTaskStore(),
      ...taskStore
    });
  }

  //
  // Clean project's task
  //
  cleanTaskStore(): void {
    this._setTaskStore({
      content: []
    } as TaskStore);
  }

  //
  // Extract tasks by container as Observable
  //
  extractByContainer$(container: string): Observable<Task[]> {
    return this.taskStore$.pipe(
      map(taskStore =>
        taskStore.content
          .filter(task => task.container === container)
          .sort((a, b) => +a.index - +b.index)
      )
    );
  }
  //
  // Extract tasks by container as none Observable
  //
  extractByContainer(container: string): Task[] {
    const taskStore = this._taskStore.getValue(); // Get the current value directly

    return taskStore.content
      .filter(task => task.container === container)
      .sort((a, b) => +a.index - +b.index);
  }

  //
  // Count Tasks by Container
  //
  countByContainer$(container: string): Observable<number> {
    return this.taskStore$.pipe(
      map(
        taskStore =>
          taskStore.content.filter(task => task.container === container).length
      )
    );
  }

  //
  // Http create task
  //

  createTaskHttp(task: Task): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    this.progressSpinnerService.show();

    const sanitizedUsername = this.localService.getSanitizedUsername();

    const uriTask = `/tasks/create?id=${sanitizedUsername}`;

    type ResponseNewTaskType = {
      task?: Task;
      message: string;
    };
    this.http.post<ResponseNewTaskType>(uriTask, task).subscribe({
      next: response => {
        const message = response.message;
        const createdTask = response.task as Task;

        const allTasks = this.getTaskStore().content as Task[];
        allTasks.push(createdTask);
        this.updateTaskStore({ content: allTasks });
        const feedbackDialog = {
          title: this.translate.instant('Dialog.Info'),
          message: `${this.translate.instant('Dialog.TaskCreated')} <br>

          <b> ${this.translate.instant('Dialog.TaskTitle')}: </b>${
            task.title
          }<br> <b> ${this.translate.instant('General.Description')}: </b> ${
            task.description
          } <br>`,
          style: 'warning'
        } as FeedbackDialog;
        this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
      },
      error: error => {
        const feedbackDialog = {
          id: 'E300',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant('Dialog.TaskNotCreated')}: <br>
            <b> ${this.translate.instant('Dialog.TaskTitle')}: </b>${
            task.title
          } <b> ${this.translate.instant('General.Description')}: </b> ${
            task.description
          } <br>
            `,
          style: 'warning'
        } as FeedbackDialog;
        this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
      },
      complete: () => {
        this.progressSpinnerService.hide();

        this.router.navigate(['/apps/task']);
      }
    });
  }

  // PUT Modify

  //
  // Http update task
  //

  updateTaskHttp(taskUpdate: Task): Observable<ResponseNewTaskType> {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return of();
    }
    if (!this.localService.getSanitizedUsername()) {
      return of();
    }

    const sanitizedUsername = this.localService.getSanitizedUsername();
    const uriTask = `/tasks/update?id=${sanitizedUsername}`;
    return this.http.put<ResponseNewTaskType>(uriTask, taskUpdate);
  }

  //
  // Http update many tasks
  //

  updateTasksHttp(tasks: Task[]): Observable<void> {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return of();
    }
    if (!this.localService.getSanitizedUsername()) {
      return of();
    }

    const sanitizedUsername = this.localService.getSanitizedUsername();
    const uriTask = `/tasks/updateMultiple?id=${sanitizedUsername}`;
    // console.log('updateTasksHttp = ', tasks);
    return this.http.put<void>(uriTask, { tasks });
  }

  getTask$(id: number): Observable<Task> {
    return this.taskStore$.pipe(
      map(taskStore => taskStore.content),
      map(tasks => tasks.filter(task => task.id === id)[0])
    );
  }

  getTaskBasedOnTaskId(): Task {
    return (
      this.getTaskStore().content.filter(
        task => task.id === this.getTaskIdStore()
      )[0] ?? null
    );
  }

  //
  // Http request for tasks
  //

  getAllTasksHttp(): Observable<void> {
    const sanitizedUsername = this.localService.getSanitizedUsername();
    // if (!sanitizedUsername) {
    //   return of(void 0);
    // }
    const uri = `/tasks/all?id=${sanitizedUsername}`;
    this.cleanTaskStore();

    return this.http.get<Task[]>(uri).pipe(
      tap(response => {
        const allTasks = response as Task[];
        this.updateTaskStore({ content: allTasks });
      }),
      map(() => void 0), // Map the response to void
      catchError(error => {
        console.error('Error fetching tasks', error);

        const feedbackDialog = {
          id: 'E305',
          title: this.translate.instant('Dialog.Error'),
          message: `${this.translate.instant('Dialog.AllTaskCreated')}`,
          style: 'warning'
        } as FeedbackDialog;
        // this.feedbackService.setFeedbackStore(feedbackDialog);
        // return of(void 0);

        const subject = new Subject<void>();

        this.feedbackService.setFeedbackStore(feedbackDialog, () => {
          subject.next(); // Signal completion
          subject.complete();
        });

        return subject.pipe(map(() => void 0));
      }),
      tap(() => {})
    );
  }

  //
  // Http delete task
  //

  deleteTaskHttp(taskToBeDeleted: Task): void {
    const isDemo = environment.demo;
    if (isDemo) {
      this.router.navigate(['/apps/blocked']);
      return;
    }
    if (!this.localService.getSanitizedUsername()) {
      return;
    }
    const sanitizedUsername = this.localService.getSanitizedUsername();
    this.progressSpinnerService.show();

    const baseUri = `/tasks/delete?id=${sanitizedUsername}`;
    const options = {
      body: { id: taskToBeDeleted.id } // Include taskId in the request body
    };

    this.http
      .delete(`${baseUri}`, options)
      .pipe()
      .subscribe({
        next: response => {
          const updatedTasks = this.getTaskStore().content.filter(
            task => task.id !== taskToBeDeleted.id
          );
          const feedbackDialog = {
            title: this.translate.instant('Dialog.Info'),
            message: `${this.translate.instant('Dialog.TaskDeleted')}: <br>
            <b> ${this.translate.instant('Dialog.TaskTitle')}: </b>${
              taskToBeDeleted.title
            } <b> ${this.translate.instant('General.Description')}: </b> ${
              taskToBeDeleted.description
            } <br>`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
          this.updateTaskStore({ content: updatedTasks });
        },
        error: error => {
          const feedbackDialog = {
            id: 'E306',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant('Dialog.TaskNotDeleted')}`,
            style: 'warning'
          } as FeedbackDialog;
          this.feedbackService.setFeedbackStore(feedbackDialog, () => {});
        },
        complete: () => {
          this.progressSpinnerService.hide();
        }
      });
  }
}
