import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // #sr console.log(request);
    // const GUID = 'f4179b26-21ac-432c-bcd8-cb4bc6e50981'; // to be considered
    const modifiedRequest = request.clone({
      setHeaders: {
        'Content-type': 'application/json'
        //   GUID,
      }
    });

    return next.handle(modifiedRequest);
  }
}
