import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, forkJoin, from } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppUserStore } from '../models/app-user.model';
import { CustomersService } from './customers.service';
import { LocalService } from './local.service';
import { ProgressSpinnerService } from './progress-spinner.service';
import { TasksService } from './tasks.service';
import { TreatmentsService } from './treatments.service';
import { VisitsService } from './visits.service';
import { AdditionalUserInfo, UserProfile } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Location } from '@angular/common';
import { UserSettingsService } from './user-settings.service';
import { SubscriptionService } from './subscription.service';
import { AdService } from './ad.service';
import { Subscription } from '../models/subscription.model';
import { FeedbackDialog } from '../models/feedback-dialog.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<firebase.User | null>; // Use firebase.User type
  private additionalUserInfoSubject =
    new BehaviorSubject<AdditionalUserInfo | null>(null);
  public additionalUserInfo$: Observable<AdditionalUserInfo | null> =
    this.additionalUserInfoSubject.asObservable();
  private displayAdsSubject = new BehaviorSubject<boolean>(false);
  public displayAds$ = this.displayAdsSubject.asObservable();
  private fullScreenAdSubject = new BehaviorSubject<boolean>(false);
  public fullScreenAd$ = this.fullScreenAdSubject.asObservable();

  constructor(
    private userSettingsService: UserSettingsService,
    private localService: LocalService,
    private visitSService: VisitsService,
    private treatmentsService: TreatmentsService,
    private tasksService: TasksService,
    private customersService: CustomersService,
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private progressSpinnerService: ProgressSpinnerService,
    public afAuth: AngularFireAuth,
    private location: Location,
    private subscriptionService: SubscriptionService,
    private translate: TranslateService,
    private adService: AdService // Inject the AdService
  ) {
    this.user$ = this.angularFireAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return from(user.getIdTokenResult(true)).pipe(
            map(() => user),
            catchError(() => of(null))
          );
        } else {
          return of(null);
        }
      }),
      catchError(error => {
        console.error('Auth state error:', error);
        return of(null);
      })
    );

    // Subscribe to subscriptionStore$ to update displayAdsSubject
    this.subscriptionService.subscriptionStore$
      .pipe(
        map(subscriptionStore => subscriptionStore.content),
        map(subscriptions =>
          subscriptions.some(subscription =>
            subscription.itemName.includes('#:1001')
          )
        )
      )
      .subscribe(isFreePlan => {
        this.displayAds(isFreePlan);
        if (isFreePlan) {
          this.adService.showFullScreenAd(); // Set the flag to show the full-screen ad
        }
      });
  }

  private readonly _appUserStore = new BehaviorSubject<AppUserStore>({
    content: {}
  } as AppUserStore);

  private _setAppUserStore(AppUserStore: AppUserStore): void {
    this._appUserStore.next(AppUserStore);
  }

  readonly appUserStore$ = this._appUserStore.asObservable();

  getAppUserStore(): AppUserStore {
    return this._appUserStore.getValue();
  }

  updateAppUserStore(appUserStore: Partial<AppUserStore>): void {
    this._setAppUserStore({
      ...this.getAppUserStore(),
      ...appUserStore
    });
  }

  cleanAppUserStore(): void {
    this._setAppUserStore({
      content: {}
    } as AppUserStore);
  }

  getAllUserStoreHttp(userIdInDb: string): boolean {
    return false;
  }

  async logout() {
    this.cleanAppUserStore();
    this.localService.clearData();
    this.visitSService.cleanVisitStore();
    this.treatmentsService.cleanTreatmentStore();
    this.customersService.cleanCustomerStore();
    this.userSettingsService.cleanUserSettingsStore();
    this.subscriptionService.cleanSubscriptionStore();
    this.tasksService.cleanTaskStore();
    this.adService.hideFullScreenAd();
    this.localService.clearData();
    this.user$ = of(null);
    try {
      await this.angularFireAuth.signOut();
      this.additionalUserInfoSubject.next(null);
      this.router.navigate(['/dashboards/dashboard1']).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error('Logout failed', error);
    }
  }

  async login() {
    this.afAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(async result => {
        const userInfo = result.additionalUserInfo;
        if (userInfo) {
          const profile = userInfo.profile
            ? (userInfo.profile as UserProfile)
            : null;
          const additionalUserInfo: AdditionalUserInfo = {
            isNewUser: userInfo.isNewUser,
            providerId: userInfo.providerId,
            username: userInfo.username,
            profile: profile
          };
          this.additionalUserInfoSubject.next(additionalUserInfo);
        }
        const user = result.user;

        if (user) {
          const providerData = user.providerData[0];
          if (providerData && providerData.photoURL) {
            const providerPhotoUrl = providerData.photoURL;

            if (user.photoURL !== providerPhotoUrl) {
              await user.updateProfile({ photoURL: providerPhotoUrl });
              await user.reload();
            }

            const idToken = await user.getIdToken();
            this.localService.saveData('idToken', idToken);

            if (user.email !== null) {
              this.localService.saveData('email', user.email);
            } else {
              this.localService.saveData('email', 'No Email');
            }

            if (user.photoURL !== null) {
              this.localService.saveData('photoURL', user.photoURL);
            } else {
              this.localService.saveData('photoURL', 'DefaultPhotoURL');
            }
            // console.log(' userInfo?.isNewUser = ', userInfo?.isNewUser);
            if (!userInfo?.isNewUser) {
              this.getAllStores().subscribe(() => {
                const isFreePlan = this.isFreePlan();
                // if (isFreePlan) {
                //   this.adService.showFullScreenAd();
                // }
                const termsApproved =
                  this.userSettingsService.getUserSettingsStore().content
                    .approve;
                // console.log(' termsApproved = ', termsApproved);

                if (termsApproved) {
                  this.updateUserState();
                } else {
                  // console.log(' Go to Login! termsApproved = ', termsApproved);
                  this.router.navigate(['apps/register'], {
                    queryParams: { step: 1 }
                  });
                }
              });
            } else {
              // console.log(' New User = ', userInfo?.isNewUser);

              const isFreePlan = this.isFreePlan();
              if (isFreePlan) {
                this.adService.showFullScreenAd();
              }
              this.updateUserState();
              this.router.navigate(['/apps/login']);
            }
          }
        }
      })
      .catch(error => {
        console.error('Login error:', error);
        //  this.additionalUserInfoSubject.next(null);
        this.router.navigate(['/apps/login']);
      });
  }

  private updateUserState() {
    this.user$ = this.angularFireAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return from(user.getIdTokenResult(true)).pipe(
            map(() => user),
            catchError(() => of(null))
          );
        } else {
          return of(null);
        }
      }),
      catchError(error => {
        console.error('Auth state error:', error);
        return of(null);
      })
    );
  }

  getAllStores(): Observable<void> {
    this.progressSpinnerService.show();
    const observables = [
      this.userSettingsService.getUserSettingsHttp(),
      this.treatmentsService.getAllTreatmentsHttp(),
      this.tasksService.getAllTasksHttp(),
      this.customersService.getAllCustomersHttp(),
      this.visitSService.getAllVisitsHttp(),
      this.subscriptionService.getAllActiveSubscriptionsHttp()
    ];

    return forkJoin(observables).pipe(
      map(() => {}),
      tap({
        next: () => {},
        error: error => {
          const feedbackDialog = {
            id: 'E10',
            title: this.translate.instant('Dialog.Error'),
            message: `${this.translate.instant(
              'Dialog.FetchStoreDataError'
            )}: <br>
              `,
            style: 'warning'
          } as FeedbackDialog;
        },
        complete: () => {
          this.progressSpinnerService.hide();
        }
      })
    );
  }

  isAuthenticated(): boolean {
    return !!this.afAuth.authState;
  }

  isFreePlan(): boolean {
    const subscriptions =
      this.subscriptionService.getSubscriptionStore().content;
    const isFree = subscriptions.some(subscription =>
      subscription.itemName.includes('#:1001')
    );
    return isFree;
  }

  displayAds(show: boolean): void {
    this.displayAdsSubject.next(show);
  }

  shouldDisplayAds(): Observable<boolean> {
    return this.displayAds$;
  }
}
